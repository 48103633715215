import React, { useState } from "react";
import money1 from "../../../assets/money.jpg";
import cost from "../../../assets/cost.svg";
import person from "../../../assets/person.svg";
import price from "../../../assets/price.svg";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { useInView } from "react-intersection-observer";

function Features() {
  const [isHovered, setIsHovered] = useState(false);
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.1 });

  if (inView1) {
    document.body.classList.add("faq-in-view4");
  }

  if (inView2) {
    document.body.classList.add("faq-in-view2");
  }

  function handleHover() {
    setIsHovered(true);
  }

  function handleLeave() {
    setIsHovered(false);
  }

  return (
    <div className="mt-40 items-center flex flex-col lg:flex-row justify-between w-full gap-10 lg:gap-24 max-w-[1170px] px-5 sm:px-12 lg:px-5">
      <div
        className="relative flex items-center w-full sm:w-[80%] lg:w-[50%] faq-item4"
        ref={ref1}
      >
        <img
          src={money1}
          className="rounded-[16px] max-h-[600px] w-full object-cover"
          alt=""
        ></img>
        <div className=" bg-[#1f1f1f] border border-[#2e2e2e] rounded-[8px] absolute bottom-10 -left-4 px-5 py-3 max-w-[250px] flex flex-col gap-3 shadoww">
          <h1 className="font-bold text-[18px]">
            Már{" "}
            {new Date().getFullYear() - new Date("2016-01-01").getFullYear()}{" "}
            éve működünk
          </h1>
          <a
            href="/elerhetoseg"
            className={`flex gap-2 cursor-pointer duration-300 ${
              isHovered && "text-[#9ca3af]"
            }`}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
          >
            Látogasson meg minket
            <Arrow
              className={`w-[17px] h-[25px] duration-300 ${
                isHovered && "translate-x-1"
              }`}
              stroke={!isHovered ? "white" : "#9ca3af"}
              alt=""
            ></Arrow>
          </a>
        </div>
      </div>

      <div className="lg:w-[50%] flex flex-col gap-5 faq-item2" ref={ref2}>
        <h1 className="text-[32px] text-center lg:text-left sm:text-[42px] font-bold">
          Amit nyújtunk
        </h1>
        {/* <div className="flex flex-col  px-5 py-5 bg-[#1f1f1f] border border-[#2e2e2e] rounded-[8px] shadoww">
          <div className="flex gap-5 ">
            <div className="bg-[#101010] w-[52px] h-[52px] flex justify-center items-center rounded-full">
              <img src={cost} className="w-[24px]" alt=""></img>
            </div>
            <div className="max-w-[80%]">
              <h2 className="text-[18px] font-bold">Nincs kezelési költség</h2>
              <p className="gry text-[15px] mt-2">
                Az elvünk a teljes átláthatóság, ezért nem számítunk fel rejtett
                díjakat. Az ügyfelek elégedettsége érdekében mindig őszinte és
                világos tájékoztatást nyújtunk a költségekről és a
                feltételekről.
              </p>
            </div>
          </div>
        </div> */}

        <div className="flex flex-col px-5 py-5 bg-[#1f1f1f] border border-[#2e2e2e] rounded-[8px] shadoww">
          <div className="flex gap-5 ">
            <div className="bg-[#101010] w-[52px] h-[52px] flex justify-center items-center rounded-full">
              <img src={person} className="w-[28px]" alt=""></img>
            </div>
            <div className="max-w-[80%]">
              <h2 className="text-[18px] font-bold">
                Professzionális munkatársak
              </h2>
              <p className="gry text-[15px] mt-2">
                Büszkék vagyunk rá, hogy csapatunk kiválóan képzett és
                tapasztalt szakemberekből áll. Így bátran fordulhatnak hozzájuk,
                hogy a lehető legjobb megoldásokat és támogatást nyújtsák.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-5 py-5 bg-[#1f1f1f] border border-[#2e2e2e] rounded-[8px] shadoww">
          <div className="flex gap-5 ">
            <div className="bg-[#101010] w-[52px] h-[52px] flex justify-center items-center rounded-full">
              <img src={price} className="w-[28px]" alt=""></img>
            </div>
            <div className="max-w-[80%]">
              <h2 className="text-[18px] font-bold">Megbízható partner</h2>
              <p className="gry text-[15px] mt-2">
                Nagy tételű váltás esetén Ügyfeleink számára kiemelt figyelmet
                biztosítunk. Legyen szó akár cégről, akár magánszemélyről, mi
                igyekszünk megfelelő feltételeket biztosítani.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
